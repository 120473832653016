@import '../constants';
@import '../type';
@import '../../lib/type_mixins';

$group-desktop-margin: 180px;
$group-phone-margin: 75px;

$previous-work-vh-height: 40vh;
$previous-work-vw-height: 40vw;
$previous-work-padding-top: 20vw;
$previous-work-link-text-height: 5vw;

.work-page,
.previous-work-container {
  background: $white;

  &.fixed {
    // intentionally don't use fixed mixin here, this has .side-gradient inside
    // it which is also position fixed, so we don't want this to be transformed
    // or it messes that up.
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 0 !important;
    z-index: $prev-work-incoming-zindex;

    .site-page-outer {
      transform: none !important;
    }
  }

  &.transitioning {
    .side-gradient {
      display: none;
    }
  }

  .site-page-outer {
    background: inherit;
  }

  .site-page-inner {
    position: relative;
    padding-bottom: $previous-work-vw-height;
    margin-left: 0;
    margin-right: 0;

    @media (min-aspect-ratio: 1/1) {
      padding-bottom: $previous-work-vh-height;
    }

    html.no-js &,
    .main-header.initialised + #content & {
      opacity: 1;
      visibility: inherit;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;

      @include desktop-and-tablet-landscape {
        height: $previous-work-padding-top;
      }

      @include tablet-portrait {
        height: $previous-work-padding-top * 1.25;
      }

      @include phone {
        height: $previous-work-padding-top * 1.5;
      }
    }
  }

  .block-group {
    @include desktop-and-tablet {
      padding-bottom: percentage($group-desktop-margin / $desktop-inner);
    }

    @include phone {
      padding-bottom: $group-phone-margin;
    }

    &:first-child {
      @include desktop-and-tablet {
        padding-bottom: percentage(250px / $desktop-inner);
      }

      @include phone {
        padding-bottom: 100px;
      }
    }

    /*
     * Block sizing / positioning
     */
    &.carousel-group {
      @include desktop-and-tablet-landscape {
        margin-left: -$desktop-margin;
        margin-right: -$desktop-margin;
      }

      @include tablet-portrait {
        margin-left: -$tablet-margin;
        margin-right: -$tablet-margin;
      }

      @include phone {
        margin-left: -$phone-margin;
        margin-right: -$phone-margin;
      }

      // Carousel is styled differently than normal to facilitate images of
      // varying aspect ratios while maintaining the same height. All blocks
      // in the carousel are rendered using base_picture.html and sized with
      // height: 100% and width: auto; Height is inherited from
      // .flickity-viewport which has it set here using a padding-top;
      //
      // For the carousel image widths to match the solo image block widths,
      // the images need to be one of the following aspect-ratios:
      // 2:3
      // 1:1
      // 3:2
      $height: 840px;

      .flickity-viewport {
        width: 100%;
        // Can't use height: 0 and padding-top hack here as Firefox doesn't
        // like it for some reason
        height: $height / $design-max * 100vw;
      }

      .work-block {
        $carousel-spacing: $desktop-margin;
        padding-left: percentage($carousel-spacing / $design-max);
        padding-right: percentage($carousel-spacing / $design-max);

        &.black {
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            // avoid subpixel issues
            left: -1px;
            right: -1px;
            background: #000;
            z-index: -1;
          }
        }

        &,
        img {
          height: 100%;
          width: auto;
        }
      }
    }

    // If the first block is an image or video it's treated as hero and styled
    // slightly differently
    &:first-child {
      &.solo,
      &.mixed {
        .image-block,
        .video-block {
          &:first-child {
            @include desktop-and-tablet-landscape {
              padding-top: $desktop-margin;
            }

            @include tablet-portrait {
              padding-top: $tablet-margin;
            }

            @include phone {
              padding-top: $phone-margin;
            }

            &.min-width668px-wide {
              @include desktop-and-tablet {
                padding-top: 0;
              }
            }

            &.max-width667px-wide {
              @include phone {
                padding-top: 0;
              }
            }

            &.min-width668px-landscape,
            &.min-width668px-square {
              @include desktop-and-tablet {
                width: 100%;
              }
            }

            &.max-width667px-landscape {
              @include phone {
                padding-top: 0;
              }
            }
          }
        }
      }

      &.carousel-group {
        @include desktop-and-tablet-landscape {
          padding-top: $desktop-margin;
        }

        @include tablet-portrait {
          padding-top: $tablet-margin;
        }

        @include phone {
          padding-top: $phone-margin;
        }
      }
    }

    &.solo {
      .image-block,
      .video-block {
        margin-left: auto;
        margin-right: auto;

        &.min-width668px-wide {
          @include desktop-and-tablet-landscape {
            margin-left: -$desktop-margin;
            margin-right: -$desktop-margin;
          }

          @include tablet-portrait {
            margin-left: -$tablet-margin;
            margin-right: -$tablet-margin;
          }
        }

        &.max-width667px-wide {
          @include phone {
            margin-left: -$phone-margin;
            margin-right: -$phone-margin;
          }
        }

        &.min-width668px-landscape {
          @include desktop-and-tablet {
            width: percentage(1260px / $desktop-inner);
          }
        }

        &.max-width667px-landscape {
          @include phone {
            margin-left: -$phone-margin;
            margin-right: -$phone-margin;
          }
        }

        &.min-width668px-square {
          @include desktop-and-tablet {
            width: percentage(1030px / $desktop-inner);
          }
        }

        &.max-width667px-square {
          @include phone-landscape {
            width: percentage(1030px / $desktop-inner);
          }

          @include phone-portrait {
            width: 100%;
          }
        }

        &.min-width668px-portrait {
          @include desktop-and-tablet {
            width: percentage(560px / $desktop-inner);
          }
        }

        &.max-width667px-portrait {
          @include phone-landscape {
            width: percentage(560px / $desktop-inner);
          }

          @include phone-portrait {
            width: 100%;
          }
        }

        .video-controls {
          width: auto;
          right: 0;
        }
      }
    }

    &.solo,
    &.all-text {
      .text-block {
        @include common-editor-styles;
        @include common-work-styles;
        @include work-solo-styles;
      }
    }

    &.mixed {
      $mixed-inner: $desktop-inner - 80px;

      .group-inner {
        @include clearfix;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        @include desktop-and-tablet {
          padding-left: percentage(80px / $desktop-inner);
        }

        html.flexbox & {
          &::after {
            display: none;
          }
        }
      }

      .work-block {
        @include phone {
          // match group margin between blocks
          margin-bottom: $group-phone-margin;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .image-block {
        @include desktop-and-tablet {
          float: left;
          width: percentage(740px / $mixed-inner);
        }

        &.max-width667px-wide {
          @include phone {
            margin-left: -$phone-margin;
            margin-right: -$phone-margin;
          }
        }

        &.max-width667px-landscape {
          @include phone-landscape {
            width: 100%;
          }

          @include phone-portrait {
            margin-left: -$phone-margin;
            margin-right: -$phone-margin;
          }
        }

        &.max-width667px-square,
        &.max-width667px-portrait {
          @include phone {
            width: 100%;
          }
        }
      }

      .text-block {
        @include common-editor-styles;
        @include common-work-styles;
        @include work-mixed-styles;
        // default to small size font
        @include responsive-font-size(
          25px, 14px, get-max-font-size(25px));
        float: right;

        @include desktop-and-tablet {
          width: percentage(525px / $mixed-inner);
        }

        @include phone {
          width: 100%;
        }

        + .text-block {
          clear: right;
        }
      }
    }
    /**/
  }

  .block-group {
    .video-player.show-controls .video-controls + .caption {
      margin-top: 0;
    }

    .heading,
    .caption {
      margin-top: 43px; // video-controls height

      h1,
      p {
        @include signifier(33px, 19px, get-max-font-size(33px));
        margin: 0;
        padding-top: 12px / 33px * 1em;
        line-height: 1;
        text-align: center;
        font-weight: normal;
      }
    }

    .video-player.featured {
      &.show-controls .video-controls + .large-playpause {
        margin-top: .5em;
      }
    }
  }

  $shadow-width: 5px;
  $blur-radius: 8px;
  .main-footer {
    position: relative;
    box-shadow: 0px $shadow-width $blur-radius 0px rgba(209,209,209,0.5);

    @include desktop-and-tablet {
      margin-top: percentage($group-desktop-margin / $desktop-inner);
    }

    @include phone {
      margin-top: $group-phone-margin;
    }

    &::before {
      //  prevent slight shadow being visible at top
      content: '';
      display: block;
      position: absolute;
      background: $white;
      bottom: 100%;
      margin-bottom: -1px;
      height: $shadow-width + $blur-radius;
      left: 0;
      right: 0;
    }
  }

  .previous-work-container {
    .previous-work-inner {
      position: absolute;
      bottom: -($shadow-width + $blur-radius);
      left: 0;
      right: 0;
      // So we can combine vh based height with vw based padding-top
      box-sizing: content-box;
      height: $previous-work-vw-height;
      // parallax effect, see works.js
      transform: translateY(40vh);

      @include desktop-and-tablet-landscape {
        padding-top: $previous-work-padding-top;
      }

      @include tablet-portrait {
        padding-top: $previous-work-padding-top * 1.25;
      }

      @include phone {
        padding-top: $previous-work-padding-top * 1.5;
      }

      @media (min-aspect-ratio: 1/1) {
        height: $previous-work-vh-height;
      }

      html.no-js & {
        // parallax effect, see works.js
        transform: translateY(0);
      }
    }

    .previous-work-link {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $prev-work-link-zindex;
      text-align: center;

      @include desktop-and-tablet-landscape {
        top: $previous-work-padding-top - $previous-work-link-text-height * 2;
      }

      @include tablet-portrait {
        top: $previous-work-padding-top * 1.25 - $previous-work-link-text-height * 3;
      }

      @include phone {
        top: $previous-work-padding-top * 1.5 - $previous-work-link-text-height * 4;
      }

      @include hover {
        .main-header.initialised + #content & + .site-page {
          opacity: $hover-opacity;

          &.transitioning {
            opacity: 1;
          }
        }
      }

      p {
        @include signifier(33px, 17px, get-max-font-size(33px));
        line-height: 1.1;
        margin: 0 0 .75em;
      }
    }

    .site-page {
      box-sizing: border-box;
      margin-bottom: 0 !important;

      .side-gradient {
        display: none;
      }
    }

    .site-page-outer {
      transform: none !important;
    }
  }
}

.work-page {
  .side-gradient {
    @include fixed;
    display: block;
    top: 0;
    height: 100vh;
    right: 0;
    z-index: 2;

    @include desktop-and-tablet {
      &::before {
        content: '';
        display: block;
        width: 8px / $design-max * 100vw;
      }

    }

    @include phone {
      &::before {
        content: '';
        display: block;
        width: 5px / $phone-portrait * 100vw;
      }
    }
  }
}

.queued-page {
  .site-page-outer {
    transform: none !important;
  }
}
