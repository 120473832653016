@import '../constants';

.carousel {
  &.show-controls {
    margin-bottom: percentage(90px / $design-max);

    .progress-bar {
      visibility: inherit;
    }
  }

  .flickity-page-dots,
  .progress-bar {
    position: absolute;
    top: 100%;
    left: percentage(50px / $design-max);
    right: percentage(50px / $design-max);
  }

  .flickity-page-dots {
    width: initial;
    margin: percentage(40px / $design-max) auto 0;
    z-index: 2;

    .dot {
      margin: 0;
      border-radius: 0;
      height: 0;
      padding-top: percentage(15px / $design-max);
      background: transparent;
      opacity: 0;
    }
  }

  .progress-bar {
    margin: percentage(45px / $design-max) auto 0;
    border-top: 1px solid $grey;
    overflow: hidden;
    visibility: hidden;

    @include desktop-and-tablet {
      $inner-height: 3px;
      height: $inner-height + 1px;

      .progress-bar-inner {
        border-top: $inner-height solid $black;
      }
    }

    @include phone {
      $inner-height: 2px;
      height: $inner-height + 1px;

      .progress-bar-inner {
        border-top: $inner-height solid $black;
      }
    }

    .progress-bar-inner {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
