@import 'c/main/lib/mixins';

//
// _constants.scss
//
// Site specific variables, mixins and generic mixins which rely on site
// specific settings.
//

$body-font-max: 18px;
$body-font-min: 16px;

// largest 32-bit integer
$cursor-zindex: 2147483647;
$overlay-zindex: $cursor-zindex - 1;
$disabled-scroll-zindex: $overlay-zindex - 1;
$prev-work-incoming-zindex: 6;
$fixer-zindex: $prev-work-incoming-zindex - 1;
$prev-work-link-zindex: $fixer-zindex - 1;
$page-zindex: $prev-work-link-zindex - 1;

$hover-opacity: .7;

$default-transition-time: getSharedVariable(transitionTime) * 1ms;

// borders are in px not em, as subpixel rendering means they can be
// inconsistent heights otherwise
$menu-desktop-normal-border-width: getSharedVariable(menuDesktopNormalBorderWidth) * 1px;
$menu-desktop-reduced-border-width: getSharedVariable(menuDesktopReducedBorderWidth) * 1px;
$menu-tablet-landscape-normal-border-width: getSharedVariable(menuTabletLandscapeNormalBorderWidth) * 1px;
$menu-tablet-landscape-reduced-border-width: getSharedVariable(menuTabletLandscapeReducedBorderWidth) * 1px;
$menu-tablet-portrait-normal-border-width: getSharedVariable(menuTabletPortraitNormalBorderWidth) * 1px;
$menu-tablet-portrait-reduced-border-width: getSharedVariable(menuTabletPortraitReducedBorderWidth) * 1px;
$menu-phone-normal-border-width: getSharedVariable(menuPhoneNormalBorderWidth) * 1px;
$menu-phone-reduced-border-width: getSharedVariable(menuPhoneReducedBorderWidth) * 1px;

$menu-reduced-height-lines: getSharedVariable(menuReducedHeightLines);
$menu-font-size: getSharedVariable(menuFontSize) * 1px;
$menu-line-em-height: getSharedVariable(menuLineEmHeight) * 1em;

$menu-desktop-closed-gap: getSharedVariable(menuDesktopClosedGap) * 1px;
$menu-desktop-half-gap: $menu-desktop-closed-gap / 2;
$menu-tablet-closed-gap: getSharedVariable(menuTabletClosedGap) * 1px;
$menu-tablet-half-gap: $menu-tablet-closed-gap / 2;
$menu-phone-closed-gap: getSharedVariable(menuPhoneClosedGap) * 1px;
$menu-phone-half-gap: $menu-phone-closed-gap / 2;

$studio-name-font-size: getSharedVariable(studioNameFontSize) * 1px;
$studio-name-reduced-font-size: getSharedVariable(studioNameReducedFontSize) * 1px;
$header-logo-width: getSharedVariable(logoWidth) * 1px;
$header-logo-height: getSharedVariable(logoHeight) * 1px;
$header-logo-em-width: getSharedVariable(logoWidthEm) * 1em;
$header-logo-em-padding-bottom: getSharedVariable(logoBottomPaddingEm) * 1em;

//
// Colors
//
$black: #171717;
$grey: #757677;
$white: #fff;

//
// Responsive settings.
//
// Throughout this project, mobile is used to refer to any mobile device
// including a tablet. Tablet is used specifially to refer to a tablet sized
// device (whether it's a actually a mobile device or not). Phone is used to
// refer specifically to a phone sized device.
$tablet-max: getSharedVariable(tabletMax) * 1px;
$desktop-min: $tablet-max + 1px;
$tablet-portrait: getSharedVariable(tabletPortrait) * 1px;
$tablet-landscape-min: $tablet-portrait + 1px;
$phone-max: getSharedVariable(phoneMax) * 1px;
$tablet-min: $phone-max + 1px;
$phone-portrait: getSharedVariable(phonePortrait) * 1px;
$phone-landscape-min: $phone-portrait + 1px;

//
// Structural
//
$design-max: getSharedVariable(designMax) * 1px;
$max-width: getSharedVariable(maxWidth);
@if ($max-width) {
  $max-width: $max-width * 1px;
}

$desktop-margin: 35px;
$desktop-inner: $design-max - $desktop-margin * 2;

$tablet-margin: 14px;
$tablet-inner: $tablet-portrait - $tablet-margin * 2;

$phone-margin: 14px;
$phone-inner: $phone-portrait - $phone-margin * 2;

@function get-max-font-size($font-size-at-design-max) {
  @if $max-width {
    $max-size: $font-size-at-design-max / $design-max * $max-width;
    @return $max-size;
  }
  @return null;
}

@mixin touch-device {
  @media (hover: none) {
    @content;
  }
}

@mixin mouse-device {
  @media (hover: hover) {
    @content;
  }

  @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   // IE11 doesn't support @media(hover)
   @content;
  }
}

// These mixins provide convenience methods for styles that should be applied
// at certain viewport sizes.

@mixin above-design-max {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: #{$design-max + 1}) {
    @content;
  }
}

@mixin desktop {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $desktop-min) {
    @content;
  }
}

@mixin desktop-and-tablet-landscape {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin desktop-and-tablet {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $tablet-min) {
    @content;
  }
}

@mixin desktop-tablet-and-phone-landscape {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-and-phone {
  @media screen and (max-width: $tablet-max) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (max-width: $tablet-max) and (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: $tablet-portrait) and (min-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-portrait-and-phone-landscape {
  @media screen and (max-width: $tablet-portrait) and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin tablet-portrait-and-phone {
  @media screen and (max-width: $tablet-portrait) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: $phone-max) {
    @content;
  }
}

@mixin phone-landscape {
  @media screen and (max-width: $phone-max) and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin phone-portrait {
  @media screen and (max-width: $phone-portrait) {
    @content;
  }
}


//
// Anchor styles
//

@mixin clear-anchor-style {
  background-image: none;
  text-decoration: none;

  .cssgradients &,
  .no-cssgradients &,
  .no-js & {
    background-image: none;
    text-decoration: none;
  }

  &:hover,
  &.hover {
    .no-touchevents &,
    .cssgradients &,
    .no-cssgradients &,
    .no-js & {
      background-image: none;
      text-decoration: none;
    }
  }

  &.current-page {
    .cssgradients &,
    .no-cssgradients &,
    .no-js & {
      background-image: none;
      text-decoration: none;
    }

    &.related-hovered {
      .cssgradients &,
      .no-cssgradients &,
      .no-js & {
        background-image: none;
        text-decoration: none;
      }
    }
  }
}

// By default:
// 1. Links are underlined, except for links to the current-page (see
//    anchors.js);
// 2. The underline is removed on hover;
// 3. Links to the current-page are underlined if a related link (see
//    anchors.js) is hovered.
@mixin default-anchor-style($color: currentColor, $weight: 1px, $offset: 1px) {
  .no-cssgradients &,
  .no-js & {
    text-decoration: underline;
  }

  .cssgradients & {
    @include underline($color: $color, $weight: $weight, $offset: $offset);
  }

  &:hover {
    .no-touchevents & {
      background-image: none;
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: none;
    }
  }

  &.hover {
    .cssgradients & {
      background-image: none;
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: none;
    }
  }

  &.current-page {
    .cssgradients & {
      background-image: none;
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: none;
    }

    &.related-hovered {
      .no-cssgradients &,
      .no-js & {
        text-decoration: underline;
      }

      .cssgradients & {
        @include underline($color: $color, $weight: $weight, $offset: $offset);
      }
    }
  }
}

// The inverse style, typically used for headers and navs:
// 1. Links are not underlined, except for links to the current-page (see
//    anchors.js);
// 2. An underline is added on hover;
// 3. The underline on the link to the current-page is removed if a related
//    link (see anchors.js) is hovered.
@mixin inverse-anchor-style($color: currentColor, $weight: 1px, $offset: 1px) {
  .cssgradients & {
    background-image: none;
  }

  .no-cssgradients &,
  .no-js & {
    text-decoration: none;
  }

  &:hover {
    .no-touchevents & {
      @include underline($color: $color, $weight: $weight, $offset: $offset);
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: underline;
    }
  }

  &.hover {
    .cssgradients & {
      @include underline($color: $color, $weight: $weight, $offset: $offset);
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: underline;
    }
  }

  &.current-page,
  &.checked {
    .cssgradients & {
      @include underline($color: $color, $weight: $weight, $offset: $offset);

      &.related-hovered {
        background-image: none;
      }
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: underline;

      &.related-hovered {
        text-decoration: none;
      }
    }
  }
}

@mixin opacity-anchor-style {
  @include hover {
    opacity: $hover-opacity;
  }

  &.current-page {
    opacity: $hover-opacity;

    &.related-hovered {
      opacity: 1;
    }
  }
}

@mixin color-anchor-style($color) {
  @include hover {
    color: $color;
  }

  &.current-page {
    color: $color;

    &.related-hovered {
      color: inherit;
    }
  }
}
