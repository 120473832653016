@import '../constants';
@import '../type';
@import '../../lib/mixins';

$videoplayer-background: transparent;
$videoplayer-controls-background: transparent;
$videoplayer-controls-color: $black;
$videoplayer-controls-active-color: transparentize($black, .2);
$videoplayer-progress-track-background: $videoplayer-background;
$videoplayer-progress-thumb-color: $videoplayer-controls-color;
$progress-bar-height: 3px;
$video-transition-time: round($default-transition-time / 3 * 2 * 100) / 100;
$large-playpause-size: 33px;
$video-controls-size: 26px;
$video-controls-minimum-size: 22px;

.video-player {
  position: relative;

  &.videoplayer-loading {
    .large-playpause {
      opacity: 1;

      &::before {
        @include desktop-and-tablet {
          width: 80%;
          height: 80%;
          left: 10%;
          top: 10%;
          margin-left: 0;
          margin-top: 0;
        }

        @include phone {
          width: 80%;
          height: 80%;
          left: 10%;
          top: 10%;
          margin-left: 0;
          margin-top: 0;
        }

        @include desktop {
          border: 2px solid currentColor;
          border-right-color: transparent;
        }

        @include tablet-and-phone {
          border: 1px solid currentColor;
          border-right-color: transparent;
        }
      }
    }
  }

  &.videoplayer-playing {
    &.featured {
      .large-playpause {
        &::before {
          background: transparent;
        }

        svg {
          display: block;
        }
      }

      @include desktop-and-tablet {
        .caption {
          visibility: hidden;
          opacity: 0;
        }

        .mask {
          opacity: .75;
          visibility: inherit;
        }
      }
    }

    .video-controls {
      opacity: 1;
      visibility: inherit;
    }

    &,
    .video-controls {
      .playpause::before {
        content: '';
        font-size: inherit;
        height: 11px / $video-controls-size * 1em;
        width: 11px / $video-controls-size * 1em;
        background: $black;
        margin: auto;
      }
    }
  }

  &.featured {
    margin-left: auto;
    margin-right: auto;

    &.videoplayer-paused {
      .poster {
        opacity: 1;
        visibility: inherit;
      }

      .video,
      .video-controls {
        opacity: 0;
        visibility: hidden;
      }

      .large-playpause {
        opacity: 1;
        visibility: inherit;
      }

      .progress-outer {
        visibility: hidden;
      }
    }

    .large-playpause {
      @include signifier($large-playpause-size, 19px, get-max-font-size($large-playpause-size));
      position: relative;
      bottom: 0;
      margin: 1em auto 0;
      padding: 1.1em;

      @include hover {
        &::before {
          transform: scale(.8);
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border: 1px solid;
        transition: transform $default-transition-time;
      }

      svg {
        width: percentage(20px / ($large-playpause-size * 2.2));
        height: percentage(20px / ($large-playpause-size * 2.2));
        max-width: 20px;
        max-height: 20px;
        margin-left: 0;
        margin-top: 0;
        transform: translate(-50%, -50%);
      }
    }

    .caption {
      position: relative;
      z-index: 3;
    }

    .mask {
      display: block;
    }
  }

  .videoplayer-inner,
  .video-controls {
    // above .mask
    z-index: 3;
  }

  .video,
  .poster,
  .caption,
  .large-playpause,
  .mask {
    transition: opacity $video-transition-time, visibility $video-transition-time;
  }

  .videoplayer-inner {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .video {
    background: $videoplayer-background;
    right: 0;
  }

  .poster {
    background: transparent;
    margin: auto;
  }

  .video-controls {
    @include signifier($video-controls-size, $video-controls-minimum-size,
      get-max-font-size($video-controls-size));
    position: relative;
    background: $videoplayer-controls-background;
    color: $videoplayer-controls-color;
    padding: 0;
    bottom: 0;
    padding-right: 5px;

    @include desktop-and-tablet {
      margin: percentage(20px / $desktop-inner) (8px / $design-max * 100vw) 0;
    }

    @include phone {
      margin: percentage(20px / $desktop-inner) (5px / $phone-portrait * 100vw) 0;
    }

    .progress-outer {
      width: auto;
      left: 0;
      right: 38px / $video-controls-size * 1em;
      top: 10px / $video-controls-size * 1em;
      height: $progress-bar-height;
      margin-right: 5px;
    }

    .left {
      float: right;
    }

    .right {
      display: none;
    }

    button {
      border-color: $videoplayer-controls-color;

      @include hover {
        color: $videoplayer-controls-active-color;
        border-color: $videoplayer-controls-active-color;

        .symbol {
          fill: $videoplayer-controls-active-color;
        }
      }
    }

    .volume-bar {
      color: $videoplayer-controls-color;

      @include videoplayer-range-thumb {
        background: $videoplayer-controls-color;
        color: $videoplayer-controls-color;
      }
    }

    .mute {
      svg {
        .symbol {
          fill: $videoplayer-controls-color;
        }
      }
    }

    .fs {
      .symbol {
        fill: $videoplayer-controls-color;
      }
    }
  }

  .large-playpause,
  .video-controls .playpause {
    @include signifier($video-controls-size, $video-controls-minimum-size,
      get-max-font-size($video-controls-size));
    width: 1em;
    height: 1em;
    border: 0;
    height: 1em;
    width: 1em;
    top: initial;
    bottom: 2px / $large-playpause-size * 1em;
    left: initial;
    right: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    color: $videoplayer-controls-color;

    @include hover {
      color: $videoplayer-controls-active-color;
      border-color: $videoplayer-controls-active-color;

      .symbol {
        fill: $videoplayer-controls-active-color;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: percentage(16px / $large-playpause-size);
      height: percentage(16px / $large-playpause-size);
      margin-left: percentage(-7px / $large-playpause-size);
      margin-top: percentage(-8px / $large-playpause-size);
    }

    .symbol {
      fill: $videoplayer-controls-color;
    }
  }

  .large-playpause {
    right: 5px;

    @include desktop-and-tablet {
      margin-right: 8px / $design-max * 100vw;
    }

    @include phone {
      margin-right: 5px / $phone-portrait * 100vw;
    }
  }

  .progress-bar {
    height: $progress-bar-height;

    @include videoplayer-range-track {
      height: $progress-bar-height;
      // background: $white;
    }

    @include videoplayer-range-thumb {
      height: $progress-bar-height;
      background: $videoplayer-controls-color;
      color: $videoplayer-controls-color;
    }
  }

  .fallback-progress {
    height: $progress-bar-height;

    .fallback-progress-bar {
      height: $progress-bar-height;
      background: $videoplayer-controls-active-color;
    }
  }

  .mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: $white;
    cursor: default;
    opacity: 0;
    visibility: hidden;
  }
}
