//
// Font-faces
//
// PB TODO:
//
// Need to test affect on caching etc and make sure this is desirable:
// 1. Change webpack conf so that .woff uses url-loader (no size limit),
//    causing it to be converted to a base64 uri.
// 2. Change webfont mixin so it creates two font-faces, one the same as
//    current but excluding the woff, the other including just the wof file and
//    named #{$name}-uri
// 3. Change mixins in _type.scss to font-family: '#{$name}', '#{$name}-uri';
//    Hopefully this will cause the cached version of the font to be used if
//    available but if not fallback to the data uri.
// 4. Move this file to critical css so we can avoid initially hiding font.
//
// Considerations:
// 1. Caching - if the fonts are cached will this prevent the browser from
//    processing the base64 fallback?
// 2. Page size - WOFF files are usually about 40kb, which becomes about 60kb
//    when base64 encoded. We want to keep the initial page under 200kb, which
//    realistically we can include only one at most (MahiFx pages are about
//    60kb, SingSing about 230kb).
// 3. HTML is currently not minified, which would help (230kb -> 200kb for
//    SingSing).
//
@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.eot');
    src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
         url('./' + $filename + '.woff2') format('woff2'),
         url('./' + $filename + '.woff') format('woff'),
         url('./' + $filename + '.ttf') format('truetype'),
         url('./' + $filename + '.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@include webfont('signifier', 'SignifierWeb-Extralight', 400);
@include webfont('signifier', 'SignifierWeb-ExtralightItalic', 400, italic);

@include webfont('untitledsans', 'UntitledSansWeb-Regular', 400);
@include webfont('untitledsans', 'UntitledSansWeb-RegularItalic', 400, italic);
