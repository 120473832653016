.site-page {
  float: left;
  width: 100%;
  margin-right: -100%;
}

.homepage {
  .main-header.initialised + #content &.transitioning-page .site-page-inner {
    opacity: 0;
    visibility: hidden;
  }
}

.work-page {
  .main-header.initialised + #content &.transitioning-page .site-page-inner {
    opacity: 0;
    visibility: hidden;
  }

  &.intra-work {
    .site-page-inner {
      opacity: 0;
      visibility: hidden;
    }
  }
}
