@import 'c/main/src/constants';

.main-header {
  html.no-js &,
  &.menu-open {
    .main-menu {
      cursor: auto;

      ul li .line-content {
        height: $menu-line-em-height;

        a {
          pointer-events: all;
        }
      }

      .main-menu-inner,
      .line-content {
        overflow: visible;
      }
    }
  }

  html.no-js &,
  &.menu-opening,
  &.menu-open {
    .index-prompt {
      opacity: 0;
    }
  }

  html.no-js &.menu-open {
    .main-menu {
      .work-line {
        a {
          @include hover {
            .singsing-media {
              visibility: inherit;
              opacity: 1;
            }
          }
        }
      }
    }
  }


  .fixer {
    &.fixed {
      @include fixed;
      top: 0;

      .fixer-structural {
        width: 100%;

        @if ($max-width) {
          max-width: $max-width;

          @media screen and (min-width: #{$max-width}) {
            margin-left: calc((100vw - (#{$max-width})) / 2);
            margin-right: calc((100vw - (#{$max-width})) / 2);
          }
        }
      }
    }
  }

  .main-menu {
    cursor: pointer;

    @include hover {
      .index-prompt {
        color: inherit;
      }
    }

    .work-line {
      a {
        .singsing-media {
          @include hardware-accelerated;
          position: absolute;  // to .work-line
          top: 50%;
          margin: 0 auto;
          z-index: 2;
          visibility: hidden;
          opacity: 0;

          @include desktop-and-tablet {
            width: percentage(450px / $desktop-inner);
            box-shadow: 90px 90px 10px 0px rgba(0,0,0,0.6);
            transform: translateY(-50%);
            right: 3em;
          }

          @include phone {
            width: percentage(180px / $phone-inner);
            box-shadow: 20px 20px 5px 0px rgba(0, 0, 0, 0.6);
            right: 1em;
          }

          @include phone-landscape {
            margin-top: -$menu-tablet-half-gap;
          }

          @include phone-portrait {
            margin-top: -$menu-phone-half-gap;
          }

          .main-header:not(.menu-open) & {
            visibility: hidden !important;
            opacity: 0 !important;
          }

          &.min-width668px-wide,
          &.min-width668px-landscape {
            @include desktop-and-tablet {
              width: percentage(700px / $desktop-inner);
            }
          }

          &.max-width667px-wide,
          &.max-width667px-landscape {
            @include phone {
              width: percentage(260px / $phone-inner);
            }
          }

          .videoplayer-inner,
          .picture {
            overflow: hidden;
          }

          .video {
            transition: none;
          }

          img,
          video {
            left: calc(-1px);
            right: calc(-1px);
            width: calc(100% + 2px);
            max-width: calc(100% + 2px);
            top: calc(-1px);
            bottom: calc(-1px);
            height: calc(100% + 2px);
            max-height: calc(100% + 2px);
          }
        }
      }
    }
  }

  .studio-name {
    a {
      body.disable-logo & {
        pointer-events: none;
      }
    }
  }
}
