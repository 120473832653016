.background-image-item,
.image {
  &.loaded {
    .aspect-maintainer {
      background: transparent;
    }

    img {
      opacity: 1;
      visibility: inherit;
    }
  }
}

.picture {
  &.loaded {
    img {
      background: transparent;
      opacity: 1;
      visibility: inherit;
    }
  }
}
