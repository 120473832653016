body.model-work.change-form {
  #blocks-group {
    .inline-related {
      .form-row.hidden {
        display: none;
      }
    }
  }
}

.tox .tox-collection__item-label {
  line-height: 1;
}
